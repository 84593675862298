<template>
  <div class="vg_wrapper">
    <el-card v-loading="isLoading">
      <editHeader
        :statusShow="false"
        :isShow="isShow"
        :btn="btn"
        :strForm="optnForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('optnForm')"
      />
      <el-form ref="optnForm" :model="optnForm" :disabled="isShow" label-width="120px" size="mini">
        <el-row>
          <el-col :md="8">
            <el-form-item label="数据名称" prop="perm_name">
              <el-input v-model="optnForm.perm_name" placeholder="数据名称" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" v-if="excShow">
            <el-form-item label="数据类型">
              <el-input v-model="showType" placeholder="类型" disabled></el-input>
              <!-- 1 公有  2 私有 -->
            </el-form-item>
          </el-col>
          {{ optnForm.optn_acct_id }}
          <el-col :md="8" v-if="optnForm.optn_scope == 2 && excShow">
            <el-form-item label="按账套查询：">
              <el-select v-model="searchForm.optn_acct_id" filterable placeholder="请选择审批账套" clearable @change="handelSelChange">
                <el-option v-for="item in acctList" :key="item.acct_id" :label="item.acct_name" :value="item.acct_id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="vd_button">
          <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
          <el-button type="danger" plain size="small" @click="delTeam()">删除</el-button>
        </div>
        <el-table ref="cntt" :data="optnForm.optn_cntt_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="48" align="center"></el-table-column>
          <el-table-column label="序号" width="48" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param1">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].param1" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param2" v-if="optnForm.param2">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-select
                  @change="val => (optnForm.optn_cntt_list[scope.$index].param2 = val.join(','))"
                  filterable
                  multiple
                  v-model="machineUsage[scope.$index]"
                  placeholder="请选择机器用途"
                  v-if="optnForm.param2 === '机器用途'"
                >
                  <el-option
                    v-for="item in [
                      { value: 1, label: '皮壳' },
                      { value: 2, label: '成品' },
                      { value: 3, label: '包装' },
                      { value: 4, label: '整箱' },
                      { value: 5, label: '辅料' },
                      { value: 6, label: '专柜' }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
                <el-input v-else v-model="optnForm.optn_cntt_list[scope.$index].param2" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param3" v-if="optnForm.param3">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].param3" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param4" v-if="optnForm.param4">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].param4" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param5" v-if="optnForm.param5">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].param5" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="所属账套" v-if="showType === '私有'">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].acct_name" maxlength="30" disabled show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="所属公司" v-if="showType === '私有'">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].cptt_name" maxlength="30" disabled show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { acctAPI } from '@api/modules/acct';
import editHeader from '@/views/component/editHeader';
export default {
  name: 'OptnEdit',
  components: {
    editHeader
  },
  data() {
    return {
      optnForm: {
        optn_id: '',
        perm_id: '',
        perm_name: '',
        param1: '',
        param2: null,
        param3: null,
        param4: null,
        param5: null,
        optn_cntt_list: []
      },
      key: 0,
      optn_cntt_copy: [],
      selectionsList: [],
      isShow: true,
      btn: {},
      copyForm: [],
      isLoading: true,
      excShow: false,
      showType: '',
      acctList: [],
      searchForm: {
        id: ''
      },
      level: '',
      machineUsage: {}
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.level = this.$cookies.get('userInfo').acct_id;
      if (this.level == 1) {
        this.getAcct();
      }
      if (this.$route.query.perm_id) {
        this.getOptnInfo();
      }
      if (this.$cookies.get('userInfo').acct_id == 1) {
        this.excShow = true;
      }
    },
    //启用编辑
    openEdit() {
      if (this.level != 1 && this.optnForm.optn_scope !== 2) {
        return this.$message.warning('该账号没有权限');
      }
      this.isShow = false;
    },
    //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then(res => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            return;
          }
          return this.$message.error(res.data.msg);
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(res => {});
    },
    //获取表单信息
    getOptnInfo() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: this.$route.query.perm_id, optn_acct_id: this.searchForm.optn_acct_id })
        .then(({ data }) => {
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
          this.copyForm = [];
          this.optnForm = data.form;
          this.btn = data.btn;
          if (this.optnForm.optn_scope === 1) {
            this.showType = '公有';
          } else if (this.optnForm.optn_scope === 2) {
            this.showType = '私有';
          }
          // 机器用途补丁================================================================
          let temp = this.optnForm.param2 === '机器用途';
          this.optnForm.optn_cntt_list.forEach((item, index) => {
            if (temp) this.$set(this.machineUsage, '' + index, item.param2?.split(',') ?? []);
            item.k = this.key;
            this.key++;
          });
          this.optn_cntt_copy = this.optnForm.optn_cntt_list;
          this.copyForm = Object.assign(this.copyForm, this.optnForm.optn_cntt_list);
        })
        .catch(err => console.error(err));
    },
    //添加
    addRow() {
      let item = {
        param1: '',
        destroy_flag: 0,
        k: this.key
      };
      this.key = this.key + 1;
      this.optnForm.optn_cntt_list.push(item);
      this.selectionsList = [];
    },
    //多选
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //多选删除
    delTeam() {
      if (this.selectionsList.length == 0) {
        return this.$message.warning('至少选择一条内容');
      }
      for (let i = 0; i < this.selectionsList.length; i++) {
        this.optnForm.optn_cntt_list.forEach(item => {
          if (item.k === this.selectionsList[i].k) {
            let currentIndex = this.optnForm.optn_cntt_list.indexOf(item);
            this.optnForm.optn_cntt_list.splice(currentIndex, 1);
          }
        });
      }
    },
    //提交表单
    submit(formName) {
      this.$confirm('确定提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              if (this.optnForm.perm_name == '工艺信息') {
                let flag = true;
                let flag2 = true;
                flag = this.optnForm.optn_cntt_list.every(item => {
                  return item.param1;
                });
                if (flag == false) {
                  return this.$message.error('请填写工艺描述');
                }
                flag2 = this.optnForm.optn_cntt_list.every(item => {
                  return item.param2;
                });
                if (flag2 == false) {
                  return this.$message.error('请填写工艺类型');
                }
              } else if (this.optnForm.perm_name == '产品类型') {
                let flag = true;
                flag = this.optnForm.optn_cntt_list.every(item => {
                  return item.param1;
                });
                if (flag == false) {
                  return this.$message.error('请填写类型名称');
                }
              } else if (this.optnForm.perm_name == '单位') {
                let flag = true;
                flag = this.optnForm.optn_cntt_list.every(item => {
                  return item.param1;
                });
                if (flag == false) {
                  return this.$message.error('请填写单位名称');
                }
              } else if (this.optnForm.perm_name == '库位') {
                let flag = true;
                flag = this.optnForm.optn_cntt_list.every(item => {
                  return item.param1;
                });
                if (flag == false) {
                  return this.$message.error('请填写库位名称');
                }
              }
              this.isLoading = true;
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(res => {
          this.$message.warning('已取消保存');
        });
    },
    // 保存
    saveInfo() {
      this.optnForm.optn_id = this.$route.query.form_id;
      let optnForm = {};
      let optnFi = [];
      optnForm = Object.assign(optnForm, this.optnForm);
      optnFi = Object.assign(optnFi, this.optn_cntt_copy);
      for (let i = this.copyForm.length - 1; i >= 0; i--) {
        if (optnFi.indexOf(this.copyForm[i]) !== -1) {
          this.copyForm.splice(i, 1);
        }
      }
      for (let i = this.copyForm.length - 1; i >= 0; i--) {
        this.copyForm[i].destroy_flag = 1;
      }
      optnFi = optnFi.concat(this.copyForm);
      optnForm.optn_cntt_list = optnFi;
      post(optnAPI.editContent, optnForm)
        .then(res => {
          if (res.data.code === 0) {
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.initData();
            this.isShow = true;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let msg = res.data.msg;
          this.$message.error(msg);
        });
    },
    handelSelChange() {
      this.getOptnInfo();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
</style>
